<template>
  <button
    class="base-button"
    @click="$emit('click', $event)"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  @include reset-button;
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 700;

  background-image: linear-gradient(88deg, $primary 0%, $secondary 100%);
  background-size: 140%;
  background-position: 0% 0%;
  color: white;
  border-radius: $border-radius;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  transition: all .4s;
  &:hover {
    transform: translateY(-0.25rem);
    background-position: 40% 0%;
    box-shadow: 3px 0 1rem rgba(0, 0, 0, 0.25);
  }
}

</style>
