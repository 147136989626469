<template>
  <div class="login container">
    <h2>Prihlásiť / registrovať</h2>
    <BaseButton @click="redirect(urls.login.auth0)" :style="{ marginRight: '1rem' }">
      Auth0
    </BaseButton>
    <BaseButton @click="redirect(urls.login.discord)">
      Discord
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      urls: {
        login: {
          auth0: `${process.env.VUE_APP_AUTH_BASE}/auth/auth0`,
          discord: `${process.env.VUE_APP_AUTH_BASE}/auth/discord`,
        },
      },
    };
  },
  methods: {
    redirect(url) {
      window.location = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  text-align: center;
  h2 {
    margin-top: 3rem;
  }
}
</style>
